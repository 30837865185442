import React, { useEffect, useRef } from 'react'
import { ApolloClient, useApolloClient } from '@apollo/client'
import { SUBSCRIPTION_GENERATE_EXPORT} from '../services_v2/gql/subscription';
import {useAppDispatch} from '../store/hooks';
import { IExport } from '../models/gql';
import { removeReportInProgress } from '../store/slices/reportsSlice';

export const useReportSubscription = () => {
  const reduxDispatch = useAppDispatch()

  const apolloClient: ApolloClient<object> = useApolloClient()

  const subscription = useRef<any>()

  useEffect(() => {

    if (!subscription.current) {
      console.log("hello")
      subscription.current = apolloClient
        .subscribe({
          query: SUBSCRIPTION_GENERATE_EXPORT,
          variables: {}
        })
        .subscribe({
          next: response => {
            reduxDispatch(removeReportInProgress());
            console.log({d: response?.data.onExportGenerated.url})
            window.open((response?.data?.onExportGenerated as IExport)?.url, '_blank')
          },
          error: err => {
            console.error('error with SUBSCRIPTION_GENERATE_EXPORT subscription', err)
          }
        })    
    }


    return () => {
      subscription?.current?.unsubscribe()
    }
  }, [])
}
