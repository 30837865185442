import React, { memo, useMemo } from 'react'
import MapMarker from '../../molecules/MapMarker/MapMarker'
import { Clusterer } from '@react-google-maps/marker-clusterer'
import { useSearchParams } from 'react-router-dom'

interface IItem {
  id: string
  coordinates: google.maps.LatLng | google.maps.LatLngLiteral
  [key: string]: any // Unpredictable
}

interface IProps {
  items: Array<IItem>
  clusterer: Clusterer
  selected_ids: Array<string>
  clickable: boolean
  onClick: (item: IItem) => void
  clusteredMarkerIds: string[]
}

const MapMarkerList: React.FC<IProps> = props => {
  const {
    items,
    clusterer,
    clickable,
    onClick,
    clusteredMarkerIds
  } = props
  const [searchParams] = useSearchParams()

  const isFaultMarkersShowing = useMemo(() => {
    return !!searchParams.get('faultHistory')
  }, [searchParams.get('faultHistory')])

  return <>
    {items.map((item, i) => (
      <MapMarker
        key={`${item.productSerial}-${item.coordinates}-${i}`}
        item={item}
        clusterer={clusterer}
        clickable={isFaultMarkersShowing || clickable}
        onClick={onClick}
        isClustered={clusteredMarkerIds.findIndex(
          markerId => markerId === item.productSerial) !== -1
        }
      />
    ))}
  </>
}

export default memo(MapMarkerList)
