import { 
  useContext, 
  useEffect, 
  useMemo, 
  useState } from 'react'
import { 
  useForm, 
  SubmitHandler } from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import { useInventoryUpdate } from '../../../hooks'
import { 
  TextField, 
  Button, 
  Text } from '../../atoms'
import { AppRootContext } from '../../../pages/AppRoot'
import { IDeviceInput } from '../../../models/gql'
import { IDevice } from '../../../models_v2/entity/device'
import { DeviceInputDto } from '../../../models_v2/dto/inventory_input'

interface IFormInput extends IDeviceInput {}

type GarageGlobalFleetMowerFormProps = {
  device?: IDevice,
  onClose?: VoidFunction
}

const GarageGlobalFleetToolTagForm = ({device, onClose}: GarageGlobalFleetMowerFormProps) => {
  const { register, watch, handleSubmit, reset, setValue } = useForm<IFormInput>()
  // TODO: UX issue, no way to unset the selected device unless component unmount
  const [currentDeviceToEdit, setCurrentDeviceToEdit] = useState<IDevice>()
  const [mutateInventory, { loading: isLoading }] = useInventoryUpdate()
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')

  const onSubmit: SubmitHandler<IFormInput> = async data => {
    const { name } = data ?? {}
    setSuccessMessage('')
    setErrorMessage('')

    try {
      if (!currentDeviceToEdit) {
        const input = new DeviceInputDto({
          productSerial: uuid(),
          // TODO: What is the default source if the form is doing an insert
          source: 'ngbr',
          name
        })

        await mutateInventory({
          variables: input.toJSON()
        })

        reset()
        setSuccessMessage('Tool Tag Added Successfully!')
      } else {
        const input = new DeviceInputDto({
          ...currentDeviceToEdit.inventory,
          productSerial: currentDeviceToEdit.productSerial,
          name
        })

        await mutateInventory({
          variables: input.toJSON()
        })

        setSuccessMessage('Tool Tag Updated Successfully!')
      }
    } catch {
      setErrorMessage('Error submitting data.')
    }
  }

  useEffect(() =>{
    if ( device ) {
      setCurrentDeviceToEdit(device)
      setValue('name', device.inventory?.name || '')
    }
  }, [device])

  return (
    <div className='h-full overflow-y-auto'>
      <form className='flex flex-col h-full' onSubmit={handleSubmit(onSubmit)}>
        <div className='flex-1 flex flex-col gap-3 mb-3'>
          {errorMessage && (
            <div className='bg-[#f8514926] border border-solid border-[#f8514966] rounded py-2 px-3 mb-3 text-center text-error'>
              <Text.Body.SmallSemiBold>{errorMessage}</Text.Body.SmallSemiBold>
            </div>
          )}

          {successMessage && (
            <div className='bg-success border border-solid border-success-dark rounded py-2 px-3 mb-3 text-center '>
              <Text.Body.SmallSemiBold>
                {successMessage}
              </Text.Body.SmallSemiBold>
            </div>
          )}

          <div className='flex flex-col gap-2'>
            <Text.Body.MediumSemiBold>Name</Text.Body.MediumSemiBold>
            <TextField
              {...register('name', { required: true })}
              className='min-h-[50px] focus:outline-none focus:border-solid focus:border-white !text-primary'
              type={'text'}
              disabled={isLoading}
            />
          </div>
        </div>

        <Button
          className='w-full'
          title={isLoading ? 'Submitting...' : 'Done'}
          disabled={!watch("name")?.trim().length || isLoading}
          type='submit'
        />
        <Button
          className='w-full mt-2 bg-primary'
          title={"Close"}
          type='button'
          onClick={ onClose }
        />
      </form>
    </div>
  )
}

export default GarageGlobalFleetToolTagForm
