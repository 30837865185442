import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {IUserAccount, ICompany} from '../../models';
import { RootState } from '../store'
import {TUserAccountRole} from '../../models/user_account';
import {mergeObjects} from '../../utils/objects';

interface UserState {
  current_user: Partial<IUserAccount> | null | any
  /**
   * Cognito user attributes (custom:company_access)
   */
  company_access: string[]
}

const initialState: UserState = {
  current_user: null,
  company_access: []
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (
      state,
      action: PayloadAction<Partial<IUserAccount> | null | undefined>
    ) => {
      // TODO: create updateCurrentUserFields for form partial user update use-case
      state.current_user = action?.payload
    },
    partialCurrentUserUpdate: (
      state,
      action: PayloadAction<Partial<IUserAccount>>
    ) => {
      state.current_user = mergeObjects(state.current_user, action?.payload)
    },
    setCompanyAccess: (state, action: PayloadAction<string[]>) => {
      state.company_access = action.payload ?? []
    },
    resetUserState: () => initialState
  }
})

export const { setCurrentUser, partialCurrentUserUpdate, setCompanyAccess, resetUserState } = userSlice.actions

export const selectUser = (state: RootState) => state.user.current_user
export const selectUserCompany = (state: RootState) => {
  return state?.organizationCompany?.companies?.find((company: ICompany) => 
    company?.companyId === state?.user?.current_user?.companies?.[0]
  )
}
export const selectUserId = (state: RootState) => state.user.current_user?.id
export const selectUserFullName = (state: RootState) =>
  `${state.user?.current_user?.first_name} ${state.user?.current_user?.last_name}`
export const selectUserRole = (state: RootState) =>
  state.user.current_user?.role
export const selectUserIsAdmin = (state: RootState) => state.user.current_user?.role === 'Administrator' || state.user.current_user?.role ==="GlobalAdmin"
export const selectUserIsNonGlobal = (state: RootState) => state.user.current_user?.role === 'Administrator' || state.user.current_user?.role ==="Viewer"
export const selectUserIsGlobalAdmin = (state: RootState) => state.user.current_user?.role ==="GlobalAdmin"

export const selectUserRoleOptionsForDropdown = ( state: RootState ) => {
  type DropdownUserRoleOption = {
    label: string,
    value: TUserAccountRole
  }
  
  type UserRolesMap = {
    [key in TUserAccountRole] : DropdownUserRoleOption
  }

  const dropdownUserRoles: UserRolesMap = {
    GlobalAdmin: {
      label: 'Global Administrator',
      value: 'GlobalAdmin'
    },
    GlobalViewer:{
      label: 'Global Viewer',
      value: 'GlobalViewer'
    },
    Administrator:{
      label: 'Administrator',
      value: 'Administrator'
    },
    Viewer:{
      label: 'View Only',
      value: 'Viewer'
    }
  }

  switch(state?.user?.current_user?.role) {
    case "GlobalAdmin":
      return Object.values(dropdownUserRoles)
    case "Administrator":
      return [
        dropdownUserRoles.Administrator,
        dropdownUserRoles.Viewer
      ]
    case "Viewer":
      return [
        dropdownUserRoles.Viewer
      ]
    case "GlobalViewer":
      return [
        dropdownUserRoles.GlobalViewer,
        dropdownUserRoles.Viewer
      ]
  }
}

export const userSliceRecuder = userSlice.reducer
export default userSlice.reducer