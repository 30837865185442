import React from 'react'
import { IToolTag } from '../../../../models'
import { DeviceDetailRowInformation } from '../../../molecules'

interface OtherInformationProps {
  toolTag: Partial<IToolTag>
}

const OtherInformation: React.FC<OtherInformationProps> = props => {
  const { toolTag } = props
  const {
    productSerial = '',
    // name: mac_address = '',
    ble_device_type = '',
    assignment = 'Unassigned'
  } = toolTag ?? {}

  return (
    <div className='flex flex-col gap-1'>
      {/* <DeviceDetailRowInformation label='Serial Number' value={serial_number || ''}/> */}
      {/* <DeviceDetailRowInformation label='Assignment' value={assignment} /> */}
      <DeviceDetailRowInformation label='Device Type' value={ble_device_type} />
      <DeviceDetailRowInformation label='MAC Address' value={productSerial} />
    </div>
  )
}

export default OtherInformation
