import React, { useEffect } from 'react'
import { SettingsTemplate } from '../../templates'
import { CompanySubNavigationBar } from '../CompanySubNavigationBar'
import { CompanyOrganizationButtons } from '../CompanyOrganizationButtons'
import { useMatch } from 'react-router-dom'
import { Routes } from '../../../routes'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import {
  EUserTypes,
  setCurrentActiveList
} from '../../../store/slices/organizationSettingsSlice'
import { CompanyOrganizationList } from '../CompanyOrganizationList'
import { CompanyOrganizationProfileDetails } from '../CompanyOrganizationProfileDetails'
import { UserProfileForm } from '..'
import { SettingsTemplateProps } from '../../templates/SettingsTemplate'
import { FormModes } from '../../../models/form'
import { FormProvider, useForm } from 'react-hook-form'
import ActionsInviteUser from '../SettingsUserAdministration/components/ActionsInviteUser'
import ActionsEditUser from '../SettingsUserAdministration/components/ActionsEditUser'
import { CompanyList } from '../CompanyList'
import SettingsOrganizationCompanySidebar from '../SettingsOrganization/components/SettingsOrganizationCompanySidebar'
import SettingsOrganizationModifyCompanySidebar from '../SettingsOrganization/components/SettingsOrganizationModifyCompanySidebar'
import OrganizationCompanyForm from '../SettingsOrganization/components/SettingsOrganizationMain/components/OrganizationCompanyForm'
import { OrganizationCompanyAssetAssigner } from '../SettingsOrganization/components/SettingsOrganizationMain/components'
import { AssetAssignerButtons } from '../AssetAssignerButtons/AssetAssignerButtons'
import { AssetAssignMowerList } from '../AssetAssignMowerList'
import SettingsOrganizationCompanyAssignAssetSidebar from '../SettingsOrganization/components/SettingsOrganizationCompanyAssignAssetSidebar'
import { AssetAssignBatteryList } from '../AssetAssignBatteryList'
import { AssetAssignTooltagList } from '../AssetAssignTooltagList'
import OrganizationListWrapper from '../OrganizationListWrapper'

export const CompanyOrganizationSubRouter = () => {
  // Read the route and decide what you want to get
  const reduxDispatch = useAppDispatch()

  // we declare the form here because it's split up between the left and right pane sometimes
  // this way we can access its context from both & avoid having to pass messages between them
  const useFormMethods = useForm()

  const isPeopleRoute = useMatch(Routes.people)
  const isCompaniesRoute = useMatch(Routes.companies)
  const isDealersRoute = useMatch(Routes.dealers)
  const isPeopleInviteRoute = useMatch(Routes.people_invite)
  const isPeopleEditRoute = useMatch(Routes.people_edit)
  const isCompaniesCreateRoute = useMatch(Routes.companies_create)
  const isCompaniesEditRoute = useMatch(Routes.companies_edit)
  const isCompaniesAssignAssetRoute = useMatch(
    Routes['organization.companies.assign_asset']
  )
  const isCompaniesAssignAssetMowerRoute = useMatch(
    Routes['assign_asset.mower']
  )
  const isCompaniesAssignAssetTooltagRoute = useMatch(
    Routes['assign_asset.tool_tag']
  )
  const isCompaniesAssignAssetBatteryRoute = useMatch(
    Routes['assign_asset.battery']
  )

  useEffect(() => {
    if (isPeopleRoute) {
      reduxDispatch(setCurrentActiveList(EUserTypes.People))
    } else if (isCompaniesRoute) {
      reduxDispatch(setCurrentActiveList(EUserTypes.Companies))
    } else if (isDealersRoute) {
      reduxDispatch(setCurrentActiveList(EUserTypes.Dealers))
    }
  }, [])

  const getPanelProps = (): Partial<SettingsTemplateProps> => {
    switch (true) {
      case !!isPeopleInviteRoute:
        return {
          leftContent: <ActionsInviteUser />,
          topRightContent: <UserProfileForm />,
          bottomRightContent: undefined
        }
      case !!isPeopleEditRoute:
        return {
          leftContent: <ActionsEditUser />,
          topRightContent: <UserProfileForm mode={FormModes.Edit} />,
          bottomRightContent: undefined
        }
      case !!isCompaniesRoute:
        return {
          leftContent: <SettingsOrganizationCompanySidebar />,
          topRightContent: <CompanyOrganizationButtons />,
          bottomRightContent: <OrganizationListWrapper />
        }
      case !!isCompaniesCreateRoute:
        return {
          leftContent: (
            <SettingsOrganizationModifyCompanySidebar mode='create' />
          ),
          topRightContent: <CompanyOrganizationButtons />,
          bottomRightContent: <OrganizationCompanyForm mode='create' />
        }
      case !!isCompaniesEditRoute:
        return {
          leftContent: <SettingsOrganizationModifyCompanySidebar mode='edit' />,
          topRightContent: <CompanyOrganizationButtons />,
          bottomRightContent: <OrganizationCompanyForm mode='edit' />
        }
      case !!isCompaniesAssignAssetRoute:
        return {
          leftContent: <CompanyOrganizationProfileDetails />,
          topRightContent: <AssetAssignerButtons />,
          bottomRightContent: <OrganizationCompanyAssetAssigner />
        }
      case !!isCompaniesAssignAssetMowerRoute:
        return {
          leftContent: <SettingsOrganizationCompanyAssignAssetSidebar />,
          topRightContent: <AssetAssignerButtons />,
          bottomRightContent: <AssetAssignMowerList />
        }
      case !!isCompaniesAssignAssetTooltagRoute:
        return {
          leftContent: <SettingsOrganizationCompanyAssignAssetSidebar />,
          topRightContent: <AssetAssignerButtons />,
          bottomRightContent: <AssetAssignTooltagList />
        }
      case !!isCompaniesAssignAssetBatteryRoute:
        return {
          leftContent: <SettingsOrganizationCompanyAssignAssetSidebar />,
          topRightContent: <AssetAssignerButtons />,
          bottomRightContent: <AssetAssignBatteryList />
        }
      case !!isPeopleRoute:
        return {
          leftContent: <CompanyOrganizationProfileDetails />,
          topRightContent: <CompanyOrganizationButtons />,
          bottomRightContent: <OrganizationListWrapper />
        }
      case !!isDealersRoute:
        return {
          leftContent: <CompanyOrganizationProfileDetails />,
          topRightContent: <CompanyOrganizationButtons />,
          bottomRightContent: <></>
        }
      default:
        return {}
    }
  }

  return (
    <FormProvider {...useFormMethods}>
      <SettingsTemplate
        subNavigation={<CompanySubNavigationBar />}
        {...getPanelProps()}
      />
    </FormProvider>
  )
}
