import React, { useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { ICompany, IUserAccount } from '../../../../models'
import { TUserAccountRole } from '../../../../models/user_account'
import { selectOperatingCompany } from '../../../../store/slices/operatingCompany'
import { Checkbox, Select, Text, TextField, MultiSelect } from '../../../atoms'
import { userAccountStatusResolver } from '../../../../models/utils/status'
import moment from 'moment'
import { ErrorMessage } from '@hookform/error-message'
import { PatternFormat } from 'react-number-format'
import {
  selectUserId,
  selectUserRole,
  selectUserRoleOptionsForDropdown
} from '../../../../store/slices/userSlice'
import { FormModes } from '../../../../models/form'
import { useAppSelector } from '../../../../store/hooks'
import { selectFilteredCompanies } from '../../../../store/slices/organizationCompanySlice'
import { IMultiSelectOption } from '../../../atoms/MultiSelect'

interface UserProfileFormProps {
  mode?: FormModes
  data?: Partial<IUserAccount> | null
  onChange?: (data: Partial<IUserAccount>) => void
  /** Shows the role below the `Since` label */
  showRole?: boolean
}

export interface IUserFormInput
  extends Pick<
    IUserAccount,
    | 'id'
    | 'first_name'
    | 'last_name'
    | 'email'
    | 'phone_number'
    | 'role'
    | 'thumbnail_image_url'
    | 'status'
    | 'companies'
  > {
  companyId: ICompany['companyId']
}

export const UserProfileForm: React.FC<UserProfileFormProps> = props => {
  const { mode = FormModes.Create, data, onChange, showRole = false } = props
  const { searchFilter, selectedItem } = useAppSelector(
    state => state.organizationSettings
  )
  const companiesForForm = useAppSelector(state =>
    selectFilteredCompanies(state, {
      search: searchFilter,
      limitToUserAccess: true,
      alphabetical: true,
      asFormFormat: true
    })
  )
  const userRole = useAppSelector(state => selectUserRole(state))
  const userRolesForDropdown = useAppSelector(state =>
    selectUserRoleOptionsForDropdown(state)
  )
  const userId = useAppSelector(state => selectUserId(state))
  // TODO, clear up, what is selectedItem? probably selected user, but name needs to be clearer...
  // same with data...needs better name
  const userData = selectedItem ?? data
  const isOwnProfile = useMemo(() => {
    return userId === userData?.id
  }, [userId, userData?.id])
  const operatingCompany = useAppSelector(selectOperatingCompany)
  const {
    register,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
    reset,
    getValues
  } = useFormContext<IUserFormInput>()
  const companyId = isOwnProfile
    ? operatingCompany.companyId
    : userData?.companies[0]

  useEffect(() => {
    // TODO: This is a reverse prioritization from userData variable. Is this right?
    const user = data ?? selectedItem

    const newUserFormData = {
      first_name: '',
      last_name: '',
      email: '',
      companyId: '',
      phone_number: '',
      thumbnail_image_url: ''
    }

    if (mode === FormModes.Create) {
      reset(newUserFormData)
    } else if (user) {
      // Not all these fields are in the form. This to pass the data over to the
      // form submission and not have to derive it from elsewhere. Let's keep
      // all visible and hidden data explicitly set here so form contents are clear at a glance
      const userFormData = {
        id: user?.id,
        first_name: user?.first_name,
        last_name: user?.last_name,
        companyId,
        email: user?.email,
        phone_number: user?.phone_number,
        role: user?.role,
        status: user?.status,
        companies: user?.companies
      }
      reset(userFormData)
    }
  }, [mode, userData, data, reset])

  useEffect(() => {
    setValue('companyId', companyId)
  }, [userData?.companies?.length, operatingCompany])

  const formData = watch()

  const hasThumbnailImage = useMemo(
    () => !!formData.thumbnail_image_url,
    [formData.thumbnail_image_url]
  )

  useEffect(() => {
    onChange?.(formData)
  }, [formData])

  return (
    <div className='h-full flex flex-col'>
      <div className='flex-1 flex flex-row items-start p-4 gap-5'>
        <div className='flex flex-col gap-3 max-w-[170px]'>
          {/* <div className='h-[170px] w-[170px] m-auto relative'>
            {!hasThumbnailImage && (
              <Image
                src={ASSETS.IMAGES.COMMON.border_box}
                className='absolute'
              />
            )}
            <Image
              src={
                hasThumbnailImage
                  ? userData?.thumbnail_image_url
                  : ASSETS.IMAGES.COMMON.user_profile
              }
              className={cn('h-full w-full rounded-xl', {
                'p-[6px]': !hasThumbnailImage,
                'rounded-xl': hasThumbnailImage
              })}
            />
          </div>
          <Button
            title={hasThumbnailImage ? 'Edit Image' : 'Upload Image'}
            color='secondary'
            className='border border-solid border-white'
          /> */}

          {mode === FormModes.Edit && userData && (
            <div className='flex flex-col gap-1 dark:text-white'>
              <Text.Body.Large className='!font-bold'>
                Status: {userAccountStatusResolver(userData)}
              </Text.Body.Large>
              <Text.Body.SmallSemiBold>
                Since: {moment(userData?.created_date).format('MMMM, Do YYYY')}
              </Text.Body.SmallSemiBold>
              {showRole && (
                <Text.Body.SmallSemiBold>
                  Role: {userRole ?? ''}
                </Text.Body.SmallSemiBold>
              )}

              {!isOwnProfile && userData.status === 'Active' && (
                <div className='m-auto mt-4'>
                  <Checkbox
                    label='Deactivate'
                    onChange={e => {
                      setValue(
                        'status',
                        e.target.checked ? 'Inactive' : 'Active'
                      )
                    }}
                    checked={formData.status !== 'Active'}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        <div className='w-[300px] flex flex-col gap-2 dark:text-white'>
          <div className='flex flex-col gap-2'>
            <Text.Body.MediumSemiBold>First Name</Text.Body.MediumSemiBold>
            <TextField
              {...register('first_name', {
                required: 'First name is required'
              })}
              type='text'
              className='min-h-[50px] focus:outline-none focus:border-solid focus:border-white'
            />
            <ErrorMessage
              errors={errors}
              className='text-error'
              name='first_name'
              as='p'
            />
          </div>

          <div className='flex flex-col gap-2'>
            <Text.Body.MediumSemiBold>Last Name</Text.Body.MediumSemiBold>
            <TextField
              {...register('last_name', { required: 'Last name is required' })}
              type='text'
              className='min-h-[50px] focus:outline-none focus:border-solid focus:border-white'
            />
            <ErrorMessage
              errors={errors}
              className='text-error'
              name='last_name'
              as='p'
            />
          </div>

          <div className='flex flex-col gap-2'>
            <Text.Body.MediumSemiBold>Email</Text.Body.MediumSemiBold>
            <TextField
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address'
                }
              })}
              disabled={mode === FormModes.Edit}
              type='email'
              className='min-h-[50px] focus:outline-none focus:border-solid focus:border-white'
            />
            <ErrorMessage
              errors={errors}
              className='text-error'
              name='email'
              as='p'
            />
          </div>
          <div className='flex flex-col gap-2'>
            <Text.Body.MediumSemiBold>Phone #</Text.Body.MediumSemiBold>
            <TextField
              type='text'
              readOnly
              hidden
              disabled
              {...register('phone_number', {
                required: false,
                validate: {
                  invalidFormat: value => {
                    if (!value?.length) return true
                    return value.length === 10
                      ? true
                      : 'Invalid phone number format'
                  }
                }
              })}
            />
            <PatternFormat
              format='###-###-####'
              valueIsNumericString
              allowEmptyFormatting
              mask='_'
              onValueChange={e => {
                if (e.value.trim().length) clearErrors('phone_number')
                setValue('phone_number', e.value)
              }}
              value={formData.phone_number}
              customInput={TextField}
              className='min-h-[50px] focus:outline-none focus:border-solid focus:border-white'
            />

            <ErrorMessage
              errors={errors}
              className='text-error'
              name='phone_number'
              as='p'
            />
          </div>

          <div className='flex flex-col gap-2'>
            {/* Workaround, because the headless ui component for select doesn't allow passing of ref */}
            <TextField
              {...register('role', { required: 'Role is required' })}
              type='text'
              readOnly
              disabled
              hidden
            />
            <div className='flex flex-col gap-2'>
              <Text.Body.MediumSemiBold>Role</Text.Body.MediumSemiBold>
              <Select<TUserAccountRole>
                options={userRolesForDropdown!}
                placeholder='Select option'
                disabled={
                  !(userRole == 'Administrator' || userRole === 'GlobalAdmin')
                }
                value={formData.role}
                onChange={value => {
                  if (value) clearErrors('role')
                  setValue('role', value as TUserAccountRole)

                  if (value === 'GlobalAdmin' || value === 'GlobalViewer') {
                    clearErrors('companyId')
                  }
                }}
              />
              <ErrorMessage
                errors={errors}
                className='text-error'
                name='role'
                as='p'
              />
            </div>
          </div>

          <div className='mt-2'>
            <TextField
              {...register('companyId', {
                required:
                  watch().role === 'Viewer' ||
                  watch().role === 'Administrator' ||
                  !watch().role
                    ? 'Company is required'
                    : false
              })}
              type='text'
              readOnly
              disabled
              hidden
            />
            <div className='flex flex-col gap-2'>
              <Text.Body.MediumSemiBold>
                Company Assignment
              </Text.Body.MediumSemiBold>
              <MultiSelect
                options={companiesForForm as IMultiSelectOption<string>[]}
                placeholder='Company List'
                value={getValues()['companies'] || userData?.companies}
                disabled={
                  formData.role === 'GlobalAdmin' ||
                  formData.role === 'GlobalViewer'
                }
                onChange={value => {
                  if (!value.length) clearErrors('companies')

                  setValue('companies', value)
                }}
              />
            </div>
            {errors.companyId && (
              <p className='text-error mt-1'>{errors.companyId.message}</p>
            )}
          </div>
        </div>
      </div>

      {userData?.status === 'Inactive' && (
        <div className='p-[30px]'>
          <div className='flex h-[44px] text-white'>
            <Checkbox
              label='Reactivate and Send New Invite'
              onChange={e => {
                setValue('status', e.target.checked ? 'Active' : 'Inactive')
              }}
              checked={formData.status === 'Active'}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default UserProfileForm
