import {
  useCallback,
  useContext,
  useMemo,
  useState } from 'react'
import cn from 'classnames'
import { DeviceCounterItem } from '..'
import {
  Button,
  ResizeHandle,
  TextField } from '../../atoms'
import { GarageGlobalFleetDeviceList } from '../../organisms'
import {
  useBatterySearchV2,
  useMowerSearchV2} from '../../../hooks'
import { ReportingPageContext } from '../../../pages/ReportingPage'
import { TReportingType } from '../../../pages/ReportingPage/reducers/types'
import { Resizable } from 're-resizable'
import { IDevice } from '../../../models_v2/entity/device'
import { selectDevices, selectFilteredDevices } from '../../../store/slices/deviceSliceV2/selectors'
import { useAppSelector } from '../../../store/hooks'
import { selectOperatingCompany } from '../../../store/slices/operatingCompany'

const MOWER_HIDDEN_COLUMNS: string[] = [
  'registering_dealer',
  'has_subscription',
  'network_status',
  'actions'
]

const BATTERY_HIDDEN_COLUMNS: string[] = [
  'has_subscription',
  'network_status',
  'actions'
]

const MIN_HEIGHT = 340
const MAX_HEIGHT = 780

interface ReportingAssetSelectorProps {
  reportingType: TReportingType
  className?: string
}

const ReportingAssetSelector: React.FC<ReportingAssetSelectorProps> = props => {
  const { reportingType, className } = props
  const { state: reportingPageState, dispatch: reportingPageDispatch } = useContext(ReportingPageContext)
  const [selectedDeviceType, setSelectedDeviceType] = useState<'mower' | 'battery'>('mower')
  const [searchText, setSearchText] = useState<string>('')
  const isMowersActive = selectedDeviceType === 'mower'
  const isBatteriesActive = selectedDeviceType === 'battery'
  const hasSearchText = useMemo(() => !!searchText.trim().length, [searchText])
  const devices = useAppSelector(selectDevices)
  const operatingCompany = useAppSelector(selectOperatingCompany)
  const companyBatteries = useAppSelector(
    selectFilteredDevices<IDevice>({
      deviceType: "battery", 
      operatingCompany
    })) as IDevice[]
  const companyMowers = useAppSelector(
      selectFilteredDevices<IDevice>({
        deviceType: "mower", 
        operatingCompany
      })) as IDevice[]
  const filteredMowers = useMowerSearchV2({
    items: companyMowers,
    search: isMowersActive && hasSearchText ? searchText.trim() : ''
  }) as unknown as IDevice[]

  const filteredBatteries = useBatterySearchV2({
    items: companyBatteries,
    search: isBatteriesActive && hasSearchText ? searchText.trim() : ''
  }) as unknown as IDevice[]

  const handleRowClick = useCallback((_item: Record<string, any>) => {
    const item = _item as IDevice

    if (reportingPageState?.[`${reportingType}_assets_selected`]?.[item.productSerial as string]) {
      reportingPageDispatch?.({
        type: 'DESELECT_ASSET',
        payload: {
          type: reportingType,
          id: item.productSerial
        }
      })
      return
    }

    reportingPageDispatch?.({
      type: 'SELECT_ASSET',
      payload: {
        type: reportingType,
        id: item.productSerial
      }
    })
  },
    [reportingType, reportingPageState?.[`${reportingType}_assets_selected`]]
  )

  const handleDeselectAllRows = useCallback(() => {
    reportingPageDispatch?.({
      type: 'SET_ASSETS',
      payload: {
        type: reportingType,
        ids: []
      }
    })
  }, [reportingType])

  const handleSelectAllRows = useCallback(() => {
    let assetIds: string[] = []
    if (isMowersActive) {
      if (hasSearchText) {
        assetIds = filteredMowers.map(item => item.productSerial) ?? []
      } else {
        assetIds = companyMowers?.map(item => item.productSerial) ?? []
      }
    } else if (isBatteriesActive) {
      if (hasSearchText) {
        assetIds = filteredBatteries.map(item => item.productSerial) ?? []
      } else {
        assetIds = companyBatteries?.map(item => item.productSerial) ?? []
      }
    }

    reportingPageDispatch?.({
      type: 'SET_ASSETS',
      payload: {
        type: reportingType,
        ids: assetIds
      }
    })
  }, [
    isMowersActive,
    isBatteriesActive,
    hasSearchText,
    filteredMowers,
    companyBatteries,
    filteredBatteries,
    companyMowers
  ])

  const isAllRowsSelected = useMemo(() => {
    const selectedRowsLength = Object.keys(
      reportingPageState?.[`${reportingType}_assets_selected`] ?? {}
    ).length

    if (isMowersActive) {
      return companyMowers?.length === selectedRowsLength
    } else if (isBatteriesActive) {
      return companyBatteries?.length === selectedRowsLength
    }

    return false
  }, [
    isMowersActive,
    isBatteriesActive,
    companyMowers,
    companyBatteries,
    reportingType,
    reportingPageState?.[`${reportingType}_assets_selected`]
  ])

  const onRowClassNameBuilder = useCallback((_item: Record<string, any>) => {
    const item = _item as IDevice

    if (reportingPageState?.[`${reportingType}_assets_selected`]?.[item.productSerial]) {
      return 'border-primary text-primary'
    }
    return ''
  },
    [reportingType, reportingPageState?.[`${reportingType}_assets_selected`]]
  )

  return (
    <Resizable
      enable={{
        bottom: true
      }}
      defaultSize={{
        width: '100%',
        height: MIN_HEIGHT
      }}
      minHeight={MIN_HEIGHT}
      maxHeight={MAX_HEIGHT}
      handleComponent={{ bottom: <ResizeHandle /> }}
      handleWrapperClass='flex items-center justify-center'
      className={cn('flex flex-col overflow-hidden', className)}>
      <div className='py-3 px-4 flex flex-col items-start justify-between lg:flex-row lg:items-center gap-3'>
        <div className='flex gap-[20px]'>
          <DeviceCounterItem
            label='MOWERS'
            value={companyMowers?.length || '0'}
            isActive={isMowersActive}
            onClick={() => setSelectedDeviceType('mower')}
          />
          <DeviceCounterItem
            label='BATTERIES'
            value={companyBatteries?.length || '0'}
            isActive={isBatteriesActive}
            onClick={() => setSelectedDeviceType('battery')}
          />
        </div>

        <div className='w-full lg:max-w-[300px]'>
          <TextField
            placeholder='Search'
            className='min-h-[38px] w-full border-black-500 dark:placeholder-white dark:text-white bg-black/50'
            onChange={e => setSearchText(e.target.value)}
            value={searchText}
          />
        </div>
      </div>

      <div className='flex-1 h-full overflow-x-auto relative'>
        <div className='absolute top-1 right-4'>
          {devices.length==0 && (
            <Button
              className='rounded-none border border-primary !bg-black !hover:bg-black-800 !active:hover:bg-black-900'
              size='sm'
              titleSize='sm'
              titleClassname='text-primary'
              title={isAllRowsSelected ? 'Deselect All' : 'Select All'}
              onClick={
                isAllRowsSelected ? handleDeselectAllRows : handleSelectAllRows
              }
            />
          )}
        </div>

        {isMowersActive && (
          <GarageGlobalFleetDeviceList
            type='mower'
            hiddenColumns={MOWER_HIDDEN_COLUMNS}
            isLoading={devices.length==0}
            items={(hasSearchText ? filteredMowers : companyMowers ?? [])}
            tableProps={{
              onRowClick: handleRowClick,
              rowClassName: onRowClassNameBuilder
            }}
          />
        )}
        {isBatteriesActive && (
          <GarageGlobalFleetDeviceList
            type='battery'
            hiddenColumns={BATTERY_HIDDEN_COLUMNS}
            isLoading={devices.length==0}
            items={hasSearchText ? filteredBatteries : companyBatteries ?? []}
            tableProps={{
              onRowClick: handleRowClick,
              rowClassName: onRowClassNameBuilder
            }}
          />
        )}
      </div>
    </Resizable>
  )
}

export default ReportingAssetSelector
